import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function AddProduct() {
  const [title, setTitle] = useState();
  const [offer, setOffer] = useState();
  const [client, setClient] = useState();
  const [category, setCategory] = useState();
  const [link, setLink] = useState();
  const [image, setImage] = useState();
  const [feature, setFeature] = useState();
  const [mrp_rate, setMRPPrice] = useState();
  const [real_rate, setRealPrice] = useState();
  const [description, setDescription] = useState();

  const [data, setData] = useState([]);

  const [dataClient, setDataClient] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  
  const findalCategory = dataCategory?.filter((item) => item.status.includes("success"));
  const findalStore = dataClient?.filter((item) => item.post_data.includes("success"));

  const [todo, setTodo] = useState([]);

  const nevigate = useNavigate();

  const TodoHandler = () => {
    let d = feature;
    if (!d) {
      return;
    } else {
      setTodo([...todo, d]);
    }
  };

  const DeleteHandler = (id) => {
    let de = todo?.filter((ele, ind) => {
      return ind !== id;
    });
    setTodo(de);
  };

  const PostHandler = () => {
    let AllData = {
      title: title,
      offer: offer,
      client: client,
      category: category,
      link: link,
      image: image,
      feature: todo,
      mrp_rate: mrp_rate,
      real_rate: real_rate,
      description: description,
    };
    if (
      !title ||
      !client ||
      !category ||
      !link ||
      !image ||
      !description
    ) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/product`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("result ->", result);
          let mes = result.status;

          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/camping-product");
            }, 2000);
          }
        });
    }
  };

  const StoreFun = () => {
    fetch(`${URL_LINK}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataClient(result.client);
      });
  };

  const CategoryFun = () => {
    fetch(`${URL_LINK}/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataCategory(result.category);
      });
  };

  useEffect(() => {
    StoreFun();
    CategoryFun();
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Add Product
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/camping-product"
                  />
                </div>
                <div className="card-body">
                  <div className="form-data">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <div className="input-fields upload-imge">
                          <label>Upload Image</label>
                          <div className="upload-img">
                            <img src="../img/user.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Product Name</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Product Offer</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setOffer(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Store</label>
                          <select onChange={(e) => setClient(e.target.value)}>
                            <option value="Select Client" selected disabled>
                              Select Store
                            </option>
                            {findalStore?.map((val, ind) => {
                              return (
                                <option key={ind} value={val._id}>
                                  {val.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Category</label>
                          <select onChange={(e) => setCategory(e.target.value)}>
                            <option value="Select Category" selected disabled>
                              Select Category
                            </option>
                            {findalCategory?.map((val, ind) => {
                              return (
                                <option key={ind} value={val._id}>
                                  {val.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Campaign Product Url</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Product Image</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setImage(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-12"></div> */}
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Product Feature</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setFeature(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields mt-3">
                          <button
                            type="button"
                            className="btn btn-primary all_btn p-2"
                            onClick={TodoHandler}>
                            Add Feature
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="input-fields mb-0">
                          <ul className="pl-0">
                            {todo.map((val, ind) => {
                              return (
                                <li
                                  key={ind}
                                  className="d-flex justify-content-start mb-3">
                                  {val}{" "}
                                  <span
                                    className="ml-2"
                                    type="button"
                                    onClick={() => DeleteHandler(ind)}>
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="row p-0 m-0">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-fields">
                              <label>MRP Price</label>
                              <input
                                type="text"
                                placeholder="like this :"
                                onChange={(e) => setMRPPrice(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-fields">
                              <label>Offer Price</label>
                              <input
                                type="text"
                                placeholder="like this :"
                                onChange={(e) => setRealPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="input-fields">
                          <label>Product Description</label>
                          <textarea
                            type="text"
                            cols="30"
                            rows="10"
                            placeholder="like this :"
                            onChange={(e) =>
                              setDescription(e.target.value)
                            }></textarea>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <button
                            type="button"
                            className="btn btn-primary all_btn"
                            onClick={PostHandler}>
                            Add Product
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AddProduct;


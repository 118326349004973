import React from "react";
import {
  FiBold,
  FiCommand,
  FiFolder,
  FiGift,
  FiImage,
  FiInbox,
  FiLayers,
  FiMessageSquare,
  FiSettings,
  FiTool,
} from "react-icons/fi";
import { Link } from "react-router-dom";

function SideBar() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  // const blog_user = user.role
  // console.log(blog_user);

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/"
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <span className="right-icon logo-icon">
              <FiLayers />
            </span>
          </div>
          <div className="sidebar-brand-text mx-3">Cashdost</div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link className="nav-link" to="/">
            <span className="right-icon-side">
              <FiCommand />
            </span>
            <span>Dashboard</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Interface</div>

        {user?.role === "subAdmin" && user?.email === "blog@cashdost.com" ? (
          <>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/add-media">
                <span className="right-icon-side">
                  <FiImage />
                </span>
                <span>Media</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/blog">
                <span className="right-icon-side">
                  <FiBold />
                </span>
                <span>Blog</span>
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseUtilities"
                aria-expanded="true"
                aria-controls="collapseUtilities"
              >
                <span className="right-icon-side">
                  <FiTool />
                </span>
                <span>Users</span>
              </a>
              <div
                id="collapseUtilities"
                className="collapse"
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Main Pages:</h6>
                  <Link className="collapse-item" to="/users-data">
                    Users
                  </Link>
                </div>
              </div>
            </li>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">Addons</div>
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapsePages"
                aria-expanded="true"
                aria-controls="collapsePages"
              >
                <span className="right-icon-side">
                  <FiFolder />
                </span>
                <span>Pages</span>
              </a>
              <div
                id="collapsePages"
                className="collapse"
                aria-labelledby="headingPages"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Main Pages:</h6>
                  <Link className="collapse-item" to="/client-menu">
                    Store
                  </Link>
                  <Link className="collapse-item" to="/product-category">
                    Category
                  </Link>
                  <Link className="collapse-item" to="/camping-product">
                    Deals
                  </Link>
                  <Link className="collapse-item" to="/client-coupon-show">
                    Coupons
                  </Link>
                  <div className="collapse-divider"></div>
                  <div className="collapse-divider"></div>
                  <h6 className="collapse-header">Ads:</h6>
                  <Link className="collapse-item" to="/cashdost-adds-banner">
                    Banner
                  </Link>
                  <Link className="collapse-item" to="/all-Ads-data">
                    Ads
                  </Link>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/contact">
                <span className="right-icon-side">
                  <FiMessageSquare />
                </span>
                <span>Contact</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/add-media">
                <span className="right-icon-side">
                  <FiImage />
                </span>
                <span>Media</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/blog">
                <span className="right-icon-side">
                  <FiBold />
                </span>
                <span>Blog</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link collapsed" to="/blog-comment">
                <span className="right-icon-side">
                  <FiInbox />
                </span>
                <span>Blog Comment</span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </>
  );
}

export default SideBar;

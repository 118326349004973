import React from "react";
import SideBar from "../Components/SideBar";
import Navbar from "../Components/Navbar";
import Client_Coupon from "../Content/ClientCoupons/Client_Coupon";

function ClientCoupon() {
  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <Client_Coupon />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientCoupon;

import React, { useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { Editor } from "@tinymce/tinymce-react";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { useNavigate, useParams } from "react-router-dom";

function UpdatedBlog() {
  const params = useParams();
  // const [cateData, setCateData] = useState([]);
  const [title, setTitle] = useState();
  const [short_desc, setShort_desc] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState();
  const [trending, setTrending] = useState();
  const [keyword, setKeyword] = useState();

  const nevigate = useNavigate();

  const [todo, setTodo] = useState([]);

  const TodoHandler = () => {
    let d = keyword;
    if (!d) {
      return;
    } else {
      setTodo([...todo, d]);
    }
  };

  const DeleteHandler = (id) => {
    let de = todo?.filter((ele, ind) => {
      return ind != id;
    });
    setTodo(de);
  };

  const UpdatedHandler = async () => {
    let data = {
      title: title,
      short_desc: short_desc,
      image: image,
      category: category,
      description: description,
      keyword: todo,
      status: status,
      trending: trending,
    };
    try {
      let response = await fetch(`${URL_LINK}/blog/${params.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      //   console.log("blog", result);
      let mes = result.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          nevigate("/blog");
        }, 2000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      //   console.log("resutl", result);
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  const blogData = async () => {
    let response = await fetch(`${URL_LINK}/blog/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    const {
      title,
      image,
      short_desc,
      trending,
      status,
      description,
      category,
      keyword,
    } = result.blog;
    setTitle(title);
    setImage(image);
    setShort_desc(short_desc);
    setTrending(trending);
    setStatus(status);
    setDescription(description);
    setCategory(category);
    setTodo(keyword);
  };

  // const categoryData = async () => {
  //   let response = await fetch(`${URL_LINK}/category`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   let result = await response.json();
  //   setCateData(result.category);
  // };

  useEffect(() => {
    // categoryData();
    blogData();
  }, []);
  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Update Blog
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/blog"
                  />
                </div>
                <div className="card-body">
                  <div className="form-data">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <div className="input-fields upload-imge">
                          <label>Upload Image</label>
                          <div className="upload-img">
                            <img src="../img/user.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Title</label>
                          <input
                            type="text"
                            placeholder="Blog title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Category</label>
                          <input
                            type="text"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          />
                          {/* <select
                            onChange={(e) => {
                              const selectedCategory = cateData.find(
                                (val) => val._id === e.target.value
                              );
                              setCategory(selectedCategory);
                            }}>
                            <option value={category} disabled selected>
                              {category?.category}
                            </option>
                            {cateData?.map((val, ind) => {
                              return (
                                <option key={ind} value={val._id}>
                                  {val.category}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Images link</label>
                          <input
                            type="text"
                            placeholder="https://cashdost.com/image/blog.jpg"
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 p-0">
                        <div className="row p-0 m-0">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-fields">
                              <label>This blog is Trending</label>
                              <select
                                onChange={(e) => setTrending(e.target.value)}
                              >
                                <option
                                  value="select Trending"
                                  selected
                                  disabled
                                >
                                  {trending}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="input-fields">
                              <label>Blog Status</label>
                              <select
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="select status" selected disabled>
                                  {status}
                                </option>
                                <option value="pending">pending</option>
                                <option value="success">success</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Keywords</label>
                          <input
                            type="text"
                            placeholder="like this :"
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields mt-3">
                          <button
                            type="button"
                            className="btn btn-primary all_btn p-2"
                            onClick={TodoHandler}
                          >
                            Add Keywords
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="input-fields mb-0">
                          <ul className="pl-0">
                            {todo.map((val, ind) => {
                              return (
                                <li
                                  key={ind}
                                  className="d-flex justify-content-start mb-3"
                                >
                                  {val}{" "}
                                  <span
                                    className="ml-2"
                                    type="button"
                                    onClick={() => DeleteHandler(ind)}
                                  >
                                    <FiXCircle />
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="input-fields">
                          <label>Short Description</label>
                          <input
                            type="text"
                            placeholder="Note : only short description"
                            value={short_desc}
                            onChange={(e) => setShort_desc(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="input-fields">
                          <label>Description</label>
                          <Editor
                            initialValue="write a blog..."
                            onEditorChange={(newText) =>
                              setDescription(newText)
                            }
                            value={description}
                            init={{
                              height: 700,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code | help",
                              image_title: true,
                              automatic_uploads: true,
                              file_picker_types: "image",
                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                if (meta.filetype === "image") {
                                  const input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute(
                                    "accept",
                                    "http://localhost:8001/public/collection/blog/*"
                                  );
                                  input.onchange = function () {
                                    const file = this.files[0];
                                    const reader = new FileReader();
                                    reader.onload = function () {
                                      const id =
                                        "blobid" + new Date().getTime();
                                      const blobCache =
                                        window.tinymce.activeEditor.editorUpload
                                          .blobCache;
                                      const base64 =
                                        reader.result.split(",")[1];
                                      const blobInfo = blobCache.create(
                                        id,
                                        file,
                                        base64
                                      );
                                      blobCache.add(blobInfo);
                                      callback(blobInfo.blobUri(), {
                                        title: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };
                                  input.click();
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <button
                            type="button"
                            className="btn btn-primary all_btn"
                            onClick={UpdatedHandler}
                          >
                            Update Blog
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default UpdatedBlog;

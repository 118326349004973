import React from "react";

function Notes(props) {
  return (
    <>
      <div className="error-dashboard">
        <p>
          <span>Note:</span>
          {props.error}
        </p>
      </div>
    </>
  );
}

export default Notes;

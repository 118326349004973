import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const { ProtectRoute } = props;
  const History = useNavigate();

  useEffect(() => {
    let storage = localStorage.getItem("user_data");
    if (!storage) {
      History("/admin-login");
    } else {
      return;
    }
  }, []);

  return (
    <>
      <ProtectRoute />
    </>
  );
}

export default Protected;

import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Secure/Loader";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import Notes from "../../Components/Notes";

function EditClient() {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState();
  const [offer, setOffer] = useState();
  const [link, setLink] = useState();
  const [logo, setLogo] = useState();
  const [post_data, setPost_data] = useState();

  const nevigate = useNavigate();

  const params = useParams();

  //   const nevigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch(`${URL_LINK}/store/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let data = result.client;
        let { title, offer, link, logo, post_data } =
          data;
        setTitle(title);
        setOffer(offer);
        setLink(link);
        setLogo(logo);
        setPost_data(post_data);

        setLoading(false);
      });
  }, []);

  const UpdatedHandler = () => {
    let AllData = {
      title: title,
      offer: offer,
      link: link,
      logo: logo,
      post_data: post_data,
    };
    if (!title || !logo || !link) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/store/${params.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          let mes = result.status;

          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/client-menu");
            }, 2000);
          }
        });
    }
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Update Client
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/client-menu"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="form-data">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <div className="input-fields upload-imge">
                            <label>Upload logo</label>
                            <div className="upload-img">
                              <img src={logo} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields pending-fileds">
                            <label>Default product is pending phase.</label>
                            <select
                              onChange={(e) => setPost_data(e.target.value)}>
                              <option
                                value="Default post is Pending"
                                selected
                                disabled>
                                {post_data}
                              </option>
                              <option value="pending">pending</option>
                              <option value="success">success</option>
                            </select>
                            <Notes error=" Enter only client name like: Amazon, Flipkart etc." />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Client Name</label>
                          <input
                            type="text"
                            placeholder="Cashdost"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Offer</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={offer}
                              onChange={(e) => setOffer(e.target.value)}
                            />
                            <Notes error=" Enter only client name like: Amazon, Flipkart etc." />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Client website Url</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                            <Notes error=" Enter only client name like: Amazon, Flipkart etc." />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Client logo</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={logo}
                              onChange={(e) => setLogo(e.target.value)}
                            />
                            <Notes error=" Enter only client name like: Amazon, Flipkart etc." />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields">
                            <button
                              type="button"
                              className="btn btn-primary all_btn"
                              onClick={UpdatedHandler}>
                              Updated Client
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditClient;

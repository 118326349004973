import React from 'react'
import SideBar from '../Components/SideBar';
import Navbar from '../Components/Navbar';
import UserContent from '../Content/Users/UserContent';

function Users() {
    return (
        <>
          <div className="container-fuild">
            <div className="row m-0">
              <div className="col-lg-2 col-md-2 col-12 p-0">
                <SideBar />
              </div>
              <div className="col-lg-10 col-md-10 col-12 p-0">
                <Navbar />
                <UserContent />
              </div>
            </div>
          </div>
        </>
      );
}

export default Users
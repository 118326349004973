import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Profile/Login";
import PageNotFound from "./Secure/PageNotFound";
import Protected from "./Secure/Protected";
import Users from "./Pages/Users";
import SingleUsser from "./Content/Users/SingleUsser";
import AllProduct from "./Content/Product/AllProduct";
import AddProduct from "./Content/Product/AddProduct";
import AllMedia from "./Content/Media/AllMedia";
import ViewProduct from "./Content/Product/ViewProduct";
import EditProduct from "./Content/Product/EditProduct";
import AddClient from "./Content/Client/AddClient";
import Clients from "./Pages/Clients";
import EditClient from "./Content/Client/EditClient";
import ClientCoupon from "./Pages/ClientCoupon";
import AddClientCoupon from "./Content/ClientCoupons/AddClientCoupon";
import EditClientCoupon from "./Content/ClientCoupons/EditClientCoupon";
import ProductCategory from "./Pages/ProductCategory";
import AddCategory from "./Content/Category/AddCategory";
import UpdateCategory from "./Content/Category/UpdateCategory";
import Banner from "./Pages/Banner";
import AddBanner from "./Content/Banner/AddBanner";
import Contact from "./Pages/Contact";
import AllAds from "./Content/Ads/AllAds";
import AddAds from "./Content/Ads/AddAds";
import EditAds from "./Content/Ads/EditAds";
import EditBanner from "./Content/Banner/EditBanner";
import Blog from "./Pages/Blog";
import AddBlog from "./Content/Blog/AddBlog";
import UpdatedBlog from "./Content/Blog/UpdatedBlog";
import ViewBlog from "./Content/Blog/ViewBlog";
import UserContent from "./Content/Users/UserContent";
import Comments from "./Pages/Comments";

function App() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  return (
    <>
      <Routes>
        <Route path="/admin-login" element={<Login />} />
        <Route path="/" element={<Protected ProtectRoute={Home} />} />

        <Route
          path="/add-media"
          element={<Protected ProtectRoute={AllMedia} />}
        />
        <Route path="/blog" element={<Protected ProtectRoute={Blog} />} />
        <Route
          path="/add-blog"
          element={<Protected ProtectRoute={AddBlog} />}
        />
        <Route
          path="/update-blog/:id"
          element={<Protected ProtectRoute={UpdatedBlog} />}
        />
        <Route
          path="/view-blog/:id"
          element={<Protected ProtectRoute={ViewBlog} />}
        />
        <Route
          path="/users-data"
          element={<Protected ProtectRoute={Users} />}
        />
        <Route
          path="/single-user-details/:id"
          element={<Protected ProtectRoute={SingleUsser} />}
        />
        <Route
          path="/users-data"
          element={<Protected ProtectRoute={UserContent} />}
        />

        <Route
          path="/camping-product"
          element={<Protected ProtectRoute={AllProduct} />}
        />
        <Route
          path="/add-new-product"
          element={<Protected ProtectRoute={AddProduct} />}
        />
        <Route
          path="/view-single-product/:id"
          element={<Protected ProtectRoute={ViewProduct} />}
        />
        <Route
          path="/edit-single-product/:id"
          element={<Protected ProtectRoute={EditProduct} />}
        />

        <Route
          path="/add-media"
          element={<Protected ProtectRoute={AllMedia} />}
        />

        <Route
          path="/client-menu"
          element={<Protected ProtectRoute={Clients} />}
        />
        <Route
          path="/add-new-client-menu"
          element={<Protected ProtectRoute={AddClient} />}
        />
        <Route
          path="/edit-signle-client/:id"
          element={<Protected ProtectRoute={EditClient} />}
        />

        <Route
          path="/all-Ads-data"
          element={<Protected ProtectRoute={AllAds} />}
        />
        <Route
          path="/add-new-Ads"
          element={<Protected ProtectRoute={AddAds} />}
        />
        <Route
          path="/edit-new-Ads/:id"
          element={<Protected ProtectRoute={EditAds} />}
        />

        <Route
          path="/client-coupon-show"
          element={<Protected ProtectRoute={ClientCoupon} />}
        />
        <Route
          path="/add-client-coupon"
          element={<Protected ProtectRoute={AddClientCoupon} />}
        />
        <Route
          path="/update-client-coupon/:id"
          element={<Protected ProtectRoute={EditClientCoupon} />}
        />

        <Route
          path="/product-category"
          element={<Protected ProtectRoute={ProductCategory} />}
        />
        <Route
          path="/Add-product-category"
          element={<Protected ProtectRoute={AddCategory} />}
        />
        <Route
          path="/update-product-category/:id"
          element={<Protected ProtectRoute={UpdateCategory} />}
        />

        <Route
          path="/cashdost-adds-banner"
          element={<Protected ProtectRoute={Banner} />}
        />
        <Route
          path="/add-cashdost-adds-banner"
          element={<Protected ProtectRoute={AddBanner} />}
        />
        <Route
          path="/edit-cashdost-banner/:id"
          element={<Protected ProtectRoute={EditBanner} />}
        />

        <Route path="/contact" element={<Protected ProtectRoute={Contact} />} />

        <Route path="/blog" element={<Protected ProtectRoute={Blog} />} />
        <Route
          path="/add-blog"
          element={<Protected ProtectRoute={AddBlog} />}
        />
        <Route
          path="/update-blog/:id"
          element={<Protected ProtectRoute={UpdatedBlog} />}
        />
        <Route
          path="/view-blog/:id"
          element={<Protected ProtectRoute={ViewBlog} />}
        />
        <Route
          path="/blog-comment"
          element={<Protected ProtectRoute={Comments} />}
        />
        
        <Route path="/404-error" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;

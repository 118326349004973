import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { FiCopy, FiDownload, FiTrash2 } from "react-icons/fi";
import { FILE_UPLOAD, URL_LINK, getToken } from "../../Secure/Helper";
import Loader from "../../Secure/Loader";

function AllMedia() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [data, setData] = useState([]);

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/files/cashdost`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.photo);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure for delete media`);
    fetch(`${URL_LINK}/files/cashdost/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  const UploadHandler = () => {
    alert("post image");
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    fetch(`${URL_LINK}/files/cashdost`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("data", result);
        getData();
        setLoading(false);
      });
  };

  const CopyHandler = (photo) => {
    let url = `${FILE_UPLOAD}/${photo}`;
    let urln = navigator.clipboard.writeText(url);
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">Media</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="media-section">
                        <label>Upload Media Product imagesss</label>
                        <input
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                        {file ? (
                          <button
                            type="button"
                            className="btn btn-primary mt-2 upload-btn"
                            onClick={UploadHandler}>
                            <FiDownload />
                          </button>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="col-lg-12 col-md-12 col-12 mt-4">
                        <div className="row">
                          {data?.map((val, ind) => {
                            return (
                              <div
                                key={ind}
                                className="col-lg-2 col-md-2 col-6 mb-4">
                                <div className="gallery-img">
                                  <img
                                    src={`${FILE_UPLOAD}/${val.photo}`}
                                    alt=""
                                  />
                                  <div className="dis-upon">
                                    <span
                                      type="button"
                                      title="Delete"
                                      onClick={() => DeleteHandler(val._id)}>
                                      <FiTrash2 />
                                    </span>
                                    <span
                                      type="button"
                                      title="Copy url link"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          `${FILE_UPLOAD}/${val.photo}`
                                        );
                                        alert("copy url link");
                                      }}>
                                      <FiCopy />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllMedia;

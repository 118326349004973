import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

function ButtonComponent(props) {
  return (
    <>
      <div className="add-button">
        <Link to={props.links} type="button" className="btn btn-primary">
          {props.arrow} {props.title}
        </Link>
      </div>
    </>
  );
}

export default ButtonComponent;

import React, { useEffect, useState } from "react";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { FiEdit, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import Loader from "../../Secure/Loader";
import { Link } from "react-router-dom";

function AllCategory() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.category);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure delete for this ${id} `);
    fetch(`${URL_LINK}/category/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">Category</h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
              </div>
            </form>
            <ButtonComponent
              title="Add Category"
              links="/add-product-category"
            />
          </div>
          <div className="card-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="product-section">
                <div className="row">
                  {data?.map((val, ind) => {
                    const { _id, name, image } = val;
                    return (
                      <div key={ind} className="col-lg-3 col-md-3 col-12 mb-4">
                        <div className="cards">
                          <div className="card-img category">
                            <div>
                              <img src={image} alt="cashdost" />
                              <p className="mb-0">{name}</p>
                            </div>
                            <span
                              type="button"
                              className="button-arrow"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FiMoreVertical />
                            </span>
                            <div className="other-fex d-flex align-items-center justify-content-between">
                              {val.status === "success" ? (
                                <span
                                  className="status-datas"
                                  style={{ backgroundColor: "green" }}
                                >
                                  {val.status}
                                </span>
                              ) : (
                                <span
                                  className="status-datas"
                                  style={{ backgroundColor: "red" }}
                                >
                                  {val.status}
                                </span>
                              )}
                            </div>
                            <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => DeleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </button>
                              <Link
                                to={`/update-product-category/${_id}`}
                                className="dropdown-item"
                                type="button"
                              >
                                <FiEdit />
                              </Link>
                              <Link
                                // to={`/view-single-product/${_id}`}
                                className="dropdown-item"
                                type="button"
                              >
                                <FiEye />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCategory;

import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

function UpdateCategory() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [status, setStatus] = useState();
  const nevigate = useNavigate();
  const params = useParams();

  const PostHandler = () => {
    let AllData = {
      name: name,
      image: image,
      status: status,
    };
    if (!name || !image) {
      toast.error("fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/category/${params.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          let mes = result.status;
          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/product-category");
            }, 2000);
          }
        });
    }
  };

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/category/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let d = result.category;
        // console.log(d.category);
        setName(d.name);
        setImage(d.image);
        setStatus(d.status);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Add Category
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/product-category"
                  />
                </div>
                <div className="card-body">
                  <div className="form-data">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <div className="input-fields upload-imge">
                          <label>Upload Image</label>
                          <div className="upload-img">
                            {image ? (
                              <img src={image} alt="cashdost" />
                            ) : (
                              <img src="../img/user.png" alt="cashdost" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Status</label>
                          <select onChange={(e) => setStatus(e.target.value)}>
                            <option
                              value="Default post is Pending"
                              selected
                              disabled
                            >
                              {status}
                            </option>
                            <option value="pending">pending</option>
                            <option value="success">success</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Category</label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Image</label>
                          <input
                            type="text"
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12"></div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <button
                            type="button"
                            className="btn btn-primary all_btn"
                            onClick={PostHandler}
                          >
                            Update Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default UpdateCategory;

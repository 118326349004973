import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { URL_LINK, getToken } from "../../Secure/Helper";
import {
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiSmile,
  FiTrash2,
} from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import Loader from "../../Secure/Loader";
import { Link } from "react-router-dom";
import { FcBookmark } from "react-icons/fc";

function AllProduct() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // console.log(data);

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = data?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el.title.toLowerCase().includes(search);
    }
  });
  let allData = filteredData;

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/product`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.product_data);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure delete for this ${id} `);
    fetch(`${URL_LINK}/product/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    All Product
                  </h6>
                  <form className="navbar-search search-bar">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control bg-light border-0 small"
                        placeholder="search only title..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        onChange={inputHandler}
                      />
                    </div>
                  </form>
                  <ButtonComponent
                    title="Add Product"
                    links="/add-new-product"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="product-section">
                      <div className="row">
                        {allData?.map((val, ind) => {
                          const {
                            _id,
                            post_data,
                            client,
                            description,
                            image,
                            offer,
                            title,
                            today_deals,
                          } = val;
                          return (
                            <div
                              key={ind}
                              className="col-lg-3 col-md-4 col-12 mb-4"
                            >
                              <div className="cards">
                                <div className="card-img">
                                  <img src={image} alt="" />
                                  <span
                                    type="button"
                                    className="button-arrow"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FiMoreVertical />
                                  </span>
                                  <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => DeleteHandler(_id)}
                                    >
                                      <FiTrash2 />
                                    </button>
                                    <Link
                                      to={`/edit-single-product/${_id}`}
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      <FiEdit />
                                    </Link>
                                    <Link
                                      to={`/view-single-product/${_id}`}
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      <FiEye />
                                    </Link>
                                  </div>
                                  {post_data === "success" ? (
                                    <span
                                      className="status-datas top-datas"
                                      style={{ backgroundColor: "green" }}
                                    >
                                      {post_data}
                                    </span>
                                  ) : (
                                    <span
                                      className="status-datas top-datas"
                                      style={{ backgroundColor: "red" }}
                                    >
                                      {post_data}
                                    </span>
                                  )}
                                </div>
                                <div className="card-content">
                                  {today_deals == "true" ? (
                                    <div className="d-flex align-items-center justify-content-start">
                                      <span className="icon-hot-deals">
                                        <FcBookmark />
                                      </span>
                                      <h3>{title?.slice(0, 21)}</h3>
                                    </div>
                                  ) : (
                                    <h3>{title}</h3>
                                  )}
                                  {offer ? (
                                    <h4 className="mt-3 mb-3">{offer}</h4>
                                  ) : (
                                    " "
                                  )}
                                  <h5 className="d-flex justify-content-start">
                                    {" "}
                                    <span className="right-icon col-right">
                                      <FiSmile />
                                    </span>{" "}
                                    {client?.title}
                                  </h5>
                                  <p>{description?.slice(0, 55)}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllProduct;

import React, { useEffect, useState } from "react";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import Loader from "../../Secure/Loader";

import { FcCurrencyExchange } from "react-icons/fc";

function SingleUsser() {
  const [loading, setLoading] = useState(true);
  const nevigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);
  const { _id, username, role, email, createdAt, email_verified } = data || {};

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/user/all-user-data/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.result);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const DeleteHandler = (id) => {
    fetch(`${URL_LINK}/user/user-remove/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        alert(result.message);
        nevigate("/users-data");
      });
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            {loading ? (
              <Loader />
            ) : (
              <>
                {data === null ? (
                  <h5>Nothing</h5>
                ) : (
                  <div className="Single-profile">
                    <div className="main-profile">
                      <div className="profile-image">
                        <h1>{username?.slice(0, 1)}</h1>
                      </div>
                      <div className="user-content">
                        <h3>{username}</h3>
                        <h4>{email}</h4>
                      </div>
                    </div>
                    <div className="other-user-data">
                      <h3 className="pb-3">Bio Details</h3>
                      <div className="user-list">
                        <ul>
                          <li>
                            <span className="heading-text">Id</span>{" "}
                            <span>{_id}</span>
                          </li>
                          <li>
                            <span className="heading-text">Name</span>{" "}
                            <span>{username}</span>
                          </li>
                          <li>
                            <span className="heading-text">Email</span>{" "}
                            <span>{email}</span>
                          </li>
                          <li>
                            <span className="heading-text">Mail verify</span>
                            <span>{email_verified}</span>
                          </li>
                          <li>
                            <span className="heading-text">Role</span>{" "}
                            <span>{role}</span>
                          </li>
                          <li>
                            <span className="heading-text">Join Date</span>{" "}
                            <span>{createdAt}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="user-delete mb-4">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => DeleteHandler(_id)}>
                        USER DELETE
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleUsser;

import React, { useEffect, useState } from "react";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { Link } from "react-router-dom";
import Loader from "../../Secure/Loader";
import NormalUsers from "./NormalUsers";
import Admin from "./Admin";

function UserContent() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // let sumArr = data.length;

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/user/all-user-data`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.result);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Tables</h1>
        <p className="mb-4">
          DataTables is a third party plugin that is used to generate the demo
          table below. For more information about DataTables, please visit the
          <a target="_blank" href="https://datatables.net">
            official DataTables documentation
          </a>
          .
        </p>
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">
              Total Users
              {/* {sumArr} */}
            </h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
              </div>
            </form>
          </div>
          <div className="card-body">
            <div className="navtabs">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Users
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profiles-tab"
                    data-toggle="tab"
                    href="#profiles"
                    role="tab"
                    aria-controls="profiles"
                    aria-selected="false"
                  >
                    Sub Admin
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Admin
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="card-body card-body-ch">
                    <NormalUsers
                      loading={loading}
                      Loader={Loader}
                      data={data}
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="card-body card-body-ch">
                    <Admin loading={loading} Loader={Loader} data={data} />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="profiles"
                  role="tabpanel"
                  aria-labelledby="profiles-tab"
                >
                  <div className="card-body card-body-ch">
                    <Admin loading={loading} Loader={Loader} data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserContent;

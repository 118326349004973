import React, { useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent";
import {
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiSmile,
  FiTrash2,
} from "react-icons/fi";
import { FcBookmark } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { ToastContainer, toast } from "react-toastify";

function AllBlog() {
  const [data, setData] = useState([]);
  const nevigate = useNavigate();
  // console.log(data);

  const categoryData = async () => {
    let response = await fetch(`${URL_LINK}/blog`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setData(result.blog);
  };

  const deleteHandler = async (id) => {
    let response = await fetch(`${URL_LINK}/blog/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    let result = await response.json();
    let mes = result.status;
    if (mes == "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    categoryData();
  };

  const getData = () => {
    try {
      fetch(`${URL_LINK}/user/all-user-data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let resut_data = result.message;
          if (resut_data === "Not Authorized") {
            localStorage.clear();
            nevigate("/admin-login");
          } else {
            return;
          }
        });
    } catch (error) {
      if (error) {
        localStorage.clear();
        nevigate("/admin-login");
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    getData();
    categoryData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">All Blogs</h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
              </div>
            </form>
            <ButtonComponent title="Add Blog" links="/add-blog" />
          </div>
          <div className="card-body">
            <div className="product-section">
              <div className="row">
                {data?.map((val, ind) => {
                  const {
                    _id,
                    title,
                    image,
                    short_desc,
                    category,
                    status,
                    trending,
                  } = val;
                  return (
                    <div key={ind} className="col-lg-3 col-md-4 col-12 mb-4">
                      <div className="cards">
                        <div className="card-img">
                          <img src={image} alt="" />
                          <span
                            type="button"
                            className="button-arrow"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </span>
                          <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => deleteHandler(_id)}
                            >
                              <FiTrash2 />
                            </button>
                            <Link
                              to={`/update-blog/${_id}`}
                              className="dropdown-item"
                              type="button"
                            >
                              <FiEdit />
                            </Link>
                            <Link
                              to={`/view-blog/${_id}`}
                              className="dropdown-item"
                              type="button"
                            >
                              <FiEye />
                            </Link>
                          </div>
                          {status === "pending" ? (
                            <span
                              className="status-datas top-datas"
                              style={{ backgroundColor: "red" }}
                            >
                              In Active
                            </span>
                          ) : (
                            <span
                              className="status-datas top-datas"
                              style={{ backgroundColor: "green" }}
                            >
                              Active
                            </span>
                          )}
                        </div>
                        <div className="card-content">
                          <div className="d-flex align-items-center justify-content-start">
                            {trending === "Yes" ? (
                              <span className="icon-hot-deals">
                                <FcBookmark />
                              </span>
                            ) : (
                              <p></p>
                            )}
                            <h3>{title?.slice(0, 50)} </h3>
                          </div>
                          <h5 className="d-flex justify-content-start">
                            {" "}
                            <span className="right-icon col-right">
                              <FiSmile />
                            </span>{" "}
                            {category?.category}
                          </h5>
                          <p>{short_desc?.slice(0, 80)}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AllBlog;

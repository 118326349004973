import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Secure/Loader";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";

function EditClientCoupon() {
  const [loading, setLoading] = useState(true);

  const [offer, setOffer] = useState();
  const [link, setLink] = useState();
  const [coupon_input, setCoupon] = useState();
  const [client, setClient] = useState();
  const [category, setCategory] = useState();
  const [feature, setFeature] = useState();
  const [description, setDescription] = useState();
  const [post_data, setPost_data] = useState();
  const [expiredData, setExpiredDate] = useState();

  const nevigate = useNavigate();

  const params = useParams();

  const [dataClient, setDataClient] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);

  const findalCategory = dataCategory?.filter((item) =>
    item.status.includes("success")
  );
  const findalStore = dataClient?.filter((item) =>
    item.post_data.includes("success")
  );

  const [todo, setTodo] = useState([]);
  const TodoHandler = () => {
    let d = feature;
    if (!d) {
      return;
    } else {
      setTodo([...todo, d]);
    }
  };

  const DeleteHandler = (id) => {
    let de = todo?.filter((ele, ind) => {
      return ind !== id;
    });
    setTodo(de);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${URL_LINK}/coupons/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let data = result.coupons;
        // console.log(data);
        let {
          offer,
          link,
          coupon_input,
          description,
          post_data,
          category,
          client,
          feature,
          expired_date,
        } = data;
        setOffer(offer);
        setLink(link);
        setClient(client);
        setCoupon(coupon_input);
        setCategory(category);
        setTodo(feature);
        setDescription(description);
        setPost_data(post_data);
        setExpiredDate(expired_date);

        setLoading(false);
      });
  }, []);

  const UpdatedHandler = () => {
    let AllData = {
      offer: offer,
      link: link,
      coupon_input: coupon_input,
      client: client,
      category: category,
      feature: todo,
      description: description,
      post_data: post_data,
      expired_date: expiredData,
    };
    console.log("data ->", AllData);
    if (!offer || !link || !coupon_input || !description) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/coupons/${params.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          let mes = result.status;

          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/client-coupon-show");
            }, 2000);
          }
        });
    }
  };

  const StoreFun = () => {
    fetch(`${URL_LINK}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataClient(result.client);
      });
  };

  const CategoryFun = () => {
    fetch(`${URL_LINK}/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataCategory(result.category);
      });
  };

  useEffect(() => {
    StoreFun();
    CategoryFun();
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Update Client Coupons
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/client-coupon-show"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="form-data">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <div className="input-fields upload-imge">
                            <label>Upload Image</label>
                            <div className="upload-img">
                              {client ? (
                                <img src={client?.logo} alt="" />
                              ) : (
                                <img src="../../img/user.png" alt="" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields pending-fileds ">
                            <label>
                              Default coupons & deals is pending phase.
                            </label>
                            <select
                              onChange={(e) => setPost_data(e.target.value)}
                            >
                              <option
                                value="Default post_data Pending"
                                selected
                                disabled
                              >
                                {post_data}
                              </option>
                              <option value="pending">pending</option>
                              <option value="success">success</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Client Offer</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={offer}
                              onChange={(e) => setOffer(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Client Coupons Url</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Coupon</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={coupon_input}
                              onChange={(e) => setCoupon(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Coupon Store</label>
                            <select onChange={(e) => setClient(e.target.value)}>
                              <option value="Select Client" selected disabled>
                                {client?.title}
                              </option>
                              {findalStore?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Coupons Category</label>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value="Select Client" selected disabled>
                                {category?.name}
                              </option>
                              {findalCategory?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Coupon Expired Date</label>
                            <input
                              type="date"
                              placeholder="like this :"
                              value={expiredData}
                              onChange={(e) => setExpiredDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Coupons Feature</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              onChange={(e) => setFeature(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields mt-3">
                            <button
                              type="button"
                              className="btn btn-primary all_btn p-2"
                              onClick={TodoHandler}
                            >
                              Add Feature
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields mb-0">
                            <ul className="pl-0">
                              {todo.map((val, ind) => {
                                return (
                                  <li
                                    key={ind}
                                    className="d-flex justify-content-start mb-3"
                                  >
                                    {val}{" "}
                                    <span
                                      className="ml-2"
                                      type="button"
                                      onClick={() => DeleteHandler(ind)}
                                    >
                                      <FiXCircle />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields">
                            <label>Client Coupons Description</label>
                            <textarea
                              type="text"
                              cols="30"
                              rows="10"
                              placeholder="like this :"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <button
                              type="button"
                              className="btn btn-primary all_btn"
                              onClick={UpdatedHandler}
                            >
                              Updated Client Coupon
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditClientCoupon;

import React from "react";
import { Link } from "react-router-dom";

function NormalUsers({ loading, Loader, data }) {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%">
            <thead>
              <tr>
                <th>Name</th>
                <th>E-mail</th>
                {/* <th>Phone</th> */}
                <th>Email verify</th>
                <th>Role</th>
                {/* <th>Coin</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.reverse().map((val, ind) => {
                const {
                  _id,
                  username,
                  role,
                  phone,
                  email_verified,
                  email,
                } = val;
                if (val.role === "normal") {
                  return (
                    <tr key={ind}>
                      <td>{username}</td>
                      <td>
                        <Link to={`/single-user-details/${_id}`}>{email}</Link>
                      </td>
                      {/* <td>{phone}</td> */}
                      <td>{email_verified}</td>
                      <td>{role}</td>
                      {/* <td>{coin}</td> */}
                    </tr>
                  );
                } else {
                  return;
                }
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default NormalUsers;

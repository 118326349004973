import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { FiArrowLeft } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import Notes from "../../Components/Notes";
import Loader from "../../Secure/Loader";

function EditBanner() {
  const [loading, setLoading] = useState(true);

  const [store, setStore] = useState('');
  const [category, setCategory] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  const [link, setLink] = useState();
  const [status, setStatus] = useState();
  // console.log("store :", store);
  const {title:storeName} = store || {};
  const {name} = category || {};
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const findalCategory = categoryData?.filter((item) =>
    item.status.includes("success")
  );
  const findalStore = data?.filter((item) =>
    item.post_data.includes("success")
  );

  const params = useParams();
  const nevigate = useNavigate();
  
  const UpdateHandler = async () => {
    let AllData = {
      client: store,
      category: category,
      title: title,
      description: description,
      image: image,
      link: link,
      status: status,
    };
    console.log("AllData :", AllData);
    if (!image || !link) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      let response = await fetch(`${URL_LINK}/banner/${params.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(AllData),
      });
      let result = await response.json();
      let mes = result.status;

      if (mes === "failed") {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      } else {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          nevigate("/cashdost-adds-banner");
        }, 2000);
      }
    }
  };

  const getData = () => {
    fetch(`${URL_LINK}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.client);
      });
  };

  const getCategoryData = () => {
    fetch(`${URL_LINK}/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategoryData(result.category);
      });
  };

  useEffect(() => {
    getData();
    getCategoryData();
  }, [params]);

  const fetchData = async () => {
    setLoading(true);
    let response = await fetch(`${URL_LINK}/banner/${params.id}`, {
      method: "GET",
    });
    let result = await response.json();
    let data = result.banner_data;
    const { image, link, status, title, description, client, category } = data;
    // const cat = category?._id;
    // const sto = client?._id;
    // console.log(client);
    setTitle(title);
    setCategory(category);
    setStore(client);
    setDescription(description);
    setImage(image);
    setLink(link);
    setStatus(status);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Update Banner
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/cashdost-adds-banner"
                  />
                </div>
                <div className="card-body">
                  <div className="form-data">
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <div className="input-fields upload-imge">
                            <label>Upload Image</label>
                            <div className="upload-img">
                              {image ? (
                                <img src={image} alt="" />
                              ) : (
                                <img src="../img/user.png" alt="" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields pending-fileds">
                            <label>Default banner is pending phase.</label>
                            <select onChange={(e) => setStatus(e.target.value)}>
                              <option
                                value="Default post is Pending"
                                selected
                                disabled
                              >
                                {status}
                              </option>
                              <option value="pending">pending</option>
                              <option value="success">success</option>
                            </select>
                            <Notes error=" Default porduct is pending phase. but it is use pending phase then product not show on cashdost screen. " />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Store</label>
                            <select onChange={(e) => setStore(e.target.value)}>
                              <option value={storeName} disabled selected>
                                {storeName}
                              </option>
                              {findalStore?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Category</label>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value={name} disabled selected>
                                {name}
                              </option>
                              {findalCategory?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Title</label>
                            <input
                              type="text"
                              placeholder="title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Banner Image</label>
                            <input
                              type="text"
                              placeholder="banner image link"
                              value={image}
                              onChange={(e) => setImage(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields">
                            <label>Adds Link</label>
                            <input
                              type="text"
                              placeholder="adds link"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields">
                            <label>Description</label>
                            <textarea
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <button
                              type="button"
                              className="btn btn-primary all_btn"
                              onClick={UpdateHandler}
                            >
                              Update Banner
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditBanner;

import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import SideBar from "../../Components/SideBar";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Secure/Loader";

function EditProduct() {
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const [post_data, setPost_data] = useState();
  const [title, setTitle] = useState();
  const [offer, setOffer] = useState();
  const [client, setClient] = useState();
  const [category, setCategory] = useState();
  const [link, setLink] = useState();
  const [image, setImage] = useState();
  const [feature, setFeature] = useState();
  const [mrp_rate, setMRPPrice] = useState();
  const [real_rate, setRealPrice] = useState();
  const [description, setDescription] = useState();

  const [dataClient, setDataClient] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);

  const findalCategory = dataCategory?.filter((item) => item.status.includes("success"));
  const findalStore = dataClient?.filter((item) => item.post_data.includes("success"));

  const nevigate = useNavigate();
  const [todo, setTodo] = useState([]);

  const UpdateTodoHandler = () => {
    let d = feature;
    if (!d) {
      return;
    } else {
      setTodo([...todo, d]);
    }
  };

  const DeleteHandler = (id) => {
    let de = todo?.filter((ele, ind) => {
      return ind != id;
    });
    setTodo(de);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${URL_LINK}/product/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let data = result.product_data;
        let {
          post_data,
          title,
          offer,
          client,
          category,
          link,
          image,
          feature,
          mrp_rate,
          real_rate,
          description,
        } = data;
        setPost_data(post_data);
        setTitle(title);
        setOffer(offer);
        setClient(client);
        setCategory(category);
        setLink(link);
        setImage(image);
        setTodo(feature);
        setMRPPrice(mrp_rate);
        setRealPrice(real_rate);
        setDescription(description);
        setLoading(false);
      });
  }, []);

  const UpdatedHandler = () => {
    let AllData = {
      post_data: post_data,
      title: title,
      offer: offer,
      client: client,
      category: category,
      link: link,
      image: image,
      feature: todo,
      mrp_rate: mrp_rate,
      real_rate: real_rate,
      description: description,
    };
    if (
      !post_data ||
      !title ||
      !client ||
      !category ||
      !link ||
      !image ||
      !description
    ) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/product/${params.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          let mes = result.post_data;

          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/camping-product");
            }, 2000);
          }
        });
    }
  };

  const StoreFun = () => {
    fetch(`${URL_LINK}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataClient(result.client);
      });
  };

  const CategoryFun = () => {
    fetch(`${URL_LINK}/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDataCategory(result.category);
      });
  };

  useEffect(() => {
    StoreFun();
    CategoryFun();
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Updated Product
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/camping-product"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="form-data">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <div className="input-fields upload-imge">
                            <label>Upload Image</label>
                            <div className="upload-img">
                              <img src={image} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields pending-fileds">
                            <label>Default product is pending phase.</label>
                            <select
                              onChange={(e) => setPost_data(e.target.value)}
                            >
                              <option
                                value="Default post_data Pending"
                                selected
                                disabled
                              >
                                {post_data}
                              </option>
                              <option value="pending">pending</option>
                              <option value="success">success</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Product Name</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Product Offer</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={offer}
                              onChange={(e) => setOffer(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Store</label>
                            <select onChange={(e) => setClient(e.target.value)}>
                              <option value="Select Client" selected disabled>
                                {client?.title}
                              </option>
                              {findalStore?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Category</label>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value="Select Category" selected disabled>
                                {category?.name}
                              </option>
                              {findalCategory?.map((val, ind) => {
                                return (
                                  <option key={ind} value={val._id}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Campaign Product Url</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Product Image</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              value={image}
                              onChange={(e) => setImage(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <label>Product Feature</label>
                            <input
                              type="text"
                              placeholder="like this :"
                              onChange={(e) => setFeature(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields mt-3">
                            <button
                              type="button"
                              className="btn btn-primary all_btn p-2"
                              onClick={UpdateTodoHandler}
                            >
                              Update Feature
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields mb-0">
                            <ul className="pl-0">
                              {todo?.map((val, ind) => {
                                return (
                                  <li
                                    key={ind}
                                    className="d-flex justify-content-start mb-3"
                                  >
                                    {val}
                                    <span
                                      className="ml-2"
                                      type="button"
                                      onClick={() => DeleteHandler(ind)}
                                    >
                                      <FiXCircle />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="row p-0 m-0">
                            <div className="col-lg-6 col-md-6 col-12">
                              <div className="input-fields">
                                <label>MRP Price</label>
                                <input
                                  type="text"
                                  placeholder="like this :"
                                  value={mrp_rate}
                                  onChange={(e) => setMRPPrice(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                              <div className="input-fields">
                                <label>Offer Price</label>
                                <input
                                  type="text"
                                  placeholder="like this :"
                                  value={real_rate}
                                  onChange={(e) => setRealPrice(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="input-fields">
                            <label>Product Description</label>
                            <textarea
                              type="text"
                              cols="30"
                              rows="10"
                              placeholder="like this :"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="input-fields">
                            <button
                              type="button"
                              className="btn btn-primary all_btn"
                              onClick={UpdatedHandler}
                            >
                              Update Product
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditProduct;

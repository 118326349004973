import React, { useEffect, useState } from "react";
import { FILE_UPLOAD, URL_LINK, getToken } from "../../Secure/Helper";
import { Link } from "react-router-dom";
import Loader from "../../Secure/Loader";
import { FiTrash2 } from "react-icons/fi";

function ContactUs() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getContact = async () => {
    setLoading(true);
    let fe = await fetch(`${URL_LINK}/contact-us`, {
      method: "GET",
      headers: {
        "Contact-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    let result = await fe.json();
    setData(result.Contact_data);
    setLoading(false);
  };

  const DeleteHandler = async (id) => {
    alert("ok ->" + " " + id);
    setLoading(true);
    let fe = await fetch(`${URL_LINK}/contact-us/${id}`, {
      method: "DELETE",
      headers: {
        "Contact-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    let result = await fe.json();
    console.log("result", result);
    getContact();
    setLoading(false);
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Contact</h1>
        <p className="mb-4">
          DataTables is a third party plugin that is used to generate the demo
          table below. For more information about DataTables, please visit the
          <a target="_blank" href="https://cashdost.com">
            official DataTables documentation
          </a>
        </p>
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">Contact Data</h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
              </div>
            </form>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th>Other</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((val, ind) => {
                    const { _id, name, last_name, email, phone, message } = val;
                    return (
                      <tr key={ind}>
                        <td>{ind}</td>
                        <td>
                          <Link to="">{`${name} ${last_name}`}</Link>
                        </td>
                        <td>{email}</td>
                        <td>{phone}</td>
                        <td>{message} </td>
                        <td>
                          <button
                            className="funButton"
                            type="button"
                            onClick={() => DeleteHandler(_id)}>
                            <FiTrash2 />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ContactUs;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import ButtonComponent from "../../Components/ButtonComponent";
import { FiArrowLeft } from "react-icons/fi";
import { URL_LINK } from "../../Secure/Helper";
import Loader from "../../Secure/Loader";
import { FcBookmark } from "react-icons/fc";

function ViewProduct() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const params = useParams();

  const {
    _id,
    post_data,
    title,
    image,
    offer,
    category,
    client,
    client_logo,
    feature,
    today_deals,
    mrp_rate,
    real_rate,
    description,
    link,
  } = data;

  const ViewHandler = () => {
    setLoading(true);
    fetch(`${URL_LINK}/product/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.product_data);
        setLoading(false);
      });
  };

  useEffect(() => {
    ViewHandler();
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    View Product
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/camping-product"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 p-0">
                          <div className="sing-img">
                            <img src={image} alt="" />
                            {post_data === "success" ? (
                              <span
                                className="status-data"
                                style={{ backgroundColor: "green" }}>
                                {post_data}
                              </span>
                            ) : (
                              <span
                                className="status-data"
                                style={{ backgroundColor: "red" }}>
                                {post_data}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="sing-content">
                            {today_deals == "true" ? (
                              <h2>
                                <FcBookmark /> {title}
                              </h2>
                            ) : (
                              <h2>{title}</h2>
                            )}
                            {offer ? (
                              <h3 className="mt-3 mb-3">{offer}</h3>
                            ) : (
                              " "
                            )}
                            <span className=" d-flex justify-content-between mt-3 mb-3">
                              <h4>{client}</h4>
                              <h4><img src={client_logo} alt="" className="view-client-logo"/></h4>
                              <h4>{category}</h4>
                            </span>
                            <ul>
                              {feature.map((val, ind) => {
                                return <li key={ind}>{val}</li>;
                              })}
                            </ul>
                            <div className="rating d-flex justify-content-start mb-4 mt-4">
                              <h5 className="mr-5" style={{ color: "red" }}>
                                MRP <s>{mrp_rate}</s>
                              </h5>
                              <h5>{real_rate}</h5>
                            </div>
                            <Link
                              to={link}
                              type="button"
                              className="btn btn-primary mr-3"
                              target="_blank">
                              Product Link
                            </Link>
                            <Link
                              to={`/edit-single-product/${_id}`}
                              type="button"
                              className="btn btn-info">
                              Update Prodcut
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12 mt-4">
                          <p>{description}.</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewProduct;

import React, { useEffect, useState } from "react";
import { URL_LINK, getToken } from "../../Secure/Helper";
import {
  FiEdit,
  FiEye,
  FiFrown,
  FiMoreVertical,
  FiSmile,
  FiTrash2,
} from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import Loader from "../../Secure/Loader";
import { Link } from "react-router-dom";

function Client_Coupon() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [clientData, setClientData] = useState("");
  const {
    description,
    client,
    offer,
    link,
    coupon_input,
    coupon_client,
    feature,
    category,
  } = clientData;

  const [search, setSearch] = useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const filteredData = data?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el.coupon_client.toLowerCase().includes(search);
    }
  });
  let allData = filteredData;

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/coupons`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setData(result.coupons);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const ViewHandler = (id) => {
    setLoading(true);
    fetch(`${URL_LINK}/coupons/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setClientData(result.coupons);
        setLoading(false);
      });
  };

  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure delete for this ${id} `);
    fetch(`${URL_LINK}/coupons/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">
              All Client Coupons
            </h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="search only offer..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={inputHandler}
                />
              </div>
            </form>
            <ButtonComponent
              title="Add Client Coupons"
              links="/add-client-coupon"
            />
          </div>
          <div className="card-body">
            {loading ? (
              <Loader />
            ) : (
              <div className="product-section">
                <div className="row">
                  {allData?.map((val, ind) => {
                    {/* console.log(val) */}
                    const {
                      _id,
                      offer,
                      client,
                      description,
                      post_data,
                      category
                    } = val;
                    return (
                      <div key={ind} className="col-lg-3 col-md-3 col-12 mb-4">
                        <div className="cards">
                          <div className="card-img">
                            <img src={client?.logo} alt="" />
                            <span
                              type="button"
                              className="button-arrow"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <FiMoreVertical />
                            </span>
                            <div className="other-fex d-flex align-items-center justify-content-between">
                              {post_data === "success" ? (
                                <span
                                  className="status-datas"
                                  style={{ backgroundColor: "green" }}
                                >
                                  {post_data}
                                </span>
                              ) : (
                                <span
                                  className="status-datas"
                                  style={{ backgroundColor: "red" }}
                                >
                                  {post_data}
                                </span>
                              )}
                            </div>
                            <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => DeleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </button>
                              <Link
                                to={`/update-client-coupon/${_id}`}
                                className="dropdown-item"
                                type="button"
                              >
                                <FiEdit />
                              </Link>
                              <Link
                                to=""
                                className="dropdown-item"
                                type="button"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                onClick={() => ViewHandler(_id)}
                              >
                                <FiEye />
                              </Link>
                            </div>
                          </div>
                          <div className="card-content">
                            <h3>{offer?.slice(0, 32)}</h3>
                            <p>{description?.slice(0, 32)}</p>
                            <h5 className="d-flex justify-content-between">
                              <span className="right-icon col-right">
                                <FiSmile />
                              {client?.title}
                              </span>
                              <span className="right-icon col-right text-primary">
                              <FiFrown />
                              {category?.name}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {offer} | {category?.name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="client-img">
                <img src={client?.logo} alt="" />
              </div>
              <h4>{offer}.</h4>
              <h6>{coupon_client}</h6>
              <ul>
                {feature?.map((val, ind) => {
                  return <li key={ind}>{val}</li>;
                })}
              </ul>
              <h6 className="mb-2">{description}.</h6>
              {!coupon_input ? (
                ""
              ) : (
                <span className="input_coupon">
                  <h6>{coupon_input}</h6>
                </span>
              )}
              <div className="add-button">
                <Link to={link} type="button" className="btn btn-primary">
                  Coupons Links
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Client_Coupon;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import ButtonComponent from "../../Components/ButtonComponent";
import { FiArrowLeft } from "react-icons/fi";
import { URL_LINK } from "../../Secure/Helper";
import Loader from "../../Secure/Loader";
import { FcBookmark } from "react-icons/fc";

function ViewBlog() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const params = useParams();

  const {
    _id,
    title,
    image,
    description,
    short_desc,
    status,
    trending,
    category,
  } = data;

  const ViewHandler = () => {
    setLoading(true);
    fetch(`${URL_LINK}/blog/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.blog);
        setLoading(false);
      });
  };

  useEffect(() => {
    ViewHandler();
  }, []);

  const body = description;

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    View Product
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/blog"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 p-0">
                          <div className="sing-img">
                            <img src={image} alt="" />
                            {status === "success" ? (
                              <span
                                className="status-data"
                                style={{ backgroundColor: "green" }}>
                                {status}
                              </span>
                            ) : (
                              <span
                                className="status-data"
                                style={{ backgroundColor: "red" }}>
                                {status}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="sing-content">
                            {trending == "Yes" ? (
                              <h2>
                                <FcBookmark /> {title}
                              </h2>
                            ) : (
                              <h2>{title}</h2>
                            )}
                            <span className=" mt-3 mb-3">
                              <h4>{category?.category}</h4>
                              <p>{short_desc}</p>
                            </span>
                            <Link
                              to={`/update-blog/${_id}`}
                              type="button"
                              className="btn btn-info">
                              Update Prodcut
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12 mt-4">
                        <div dangerouslySetInnerHTML={{ __html: body }} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBlog;

import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import HeaderContent from "../Content/Home/HeaderContent";
import { useNavigate } from "react-router-dom";
import { URL_LINK, getToken } from "../Secure/Helper";

function Home() {
  const nevigate = useNavigate();

  const getData = () => {
    try {
      fetch(`${URL_LINK}/user/all-user-data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let resut_data = result.message;
          if (resut_data === "Not Authorized") {
            localStorage.clear();
            nevigate("/admin-login");
          } else {
            return;
          }
        });
    } catch (error) {
      if (error) {
        localStorage.clear();
        nevigate("/admin-login");
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("Please again login. Your session is close");
  }, []);

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <HeaderContent />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;

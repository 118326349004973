import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import { FiArrowLeft, FiXCircle } from "react-icons/fi";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import Notes from "../../Components/Notes";

function EditAds() {
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [link, setLink] = useState();
  const [ads_show, setShow] = useState();
  const [post_data, setPost_data] = useState();

  const nevigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    setLoading(true);
    fetch(`${URL_LINK}/campaign-banner/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let data = result.ads_data;

        let { title, image, link, post_data, ads_show } = data;

        setTitle(title);
        setImage(image);
        setLink(link);
        setShow(ads_show);
        setPost_data(post_data);

        setLoading(false);
      });
  }, []);

  const UpdatedAdsData = () => {
    let AllData = {
      title: title,
      image: image,
      link: link,
      post_data: post_data,
      ads_show: ads_show,
    };
    if (!title || !image || !link) {
      toast.error("all fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      fetch(`${URL_LINK}/campaign-banner/${params.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(AllData),
      })
        .then((res) => res.json())
        .then((result) => {
          let mes = result.status;

          if (mes === "failed") {
            toast.error(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            return;
          } else {
            toast.success(result.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              nevigate("/all-ads-data");
            }, 2000);
          }
        });
    }
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Add New Ads
                  </h6>
                  <ButtonComponent
                    arrow={<FiArrowLeft />}
                    title="Go Back"
                    links="/all-Ads-data"
                  />
                </div>
                <div className="card-body">
                  <div className="form-data">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <div className="input-fields upload-imge">
                          <label>Upload Image</label>
                          <div className="upload-img">
                            <img src="../img/user.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields pending-fileds">
                          <label>Default ads is pending phase.</label>
                          <select
                            onChange={(e) => setPost_data(e.target.value)}
                          >
                            <option
                              value="Default post is Pending"
                              selected
                              disabled
                            >
                              {post_data}
                            </option>
                            <option value="pending">pending</option>
                            <option value="success">success</option>
                          </select>
                          <Notes error=" Default porduct is pending phase. but it is use pending phase then product not show on cashdost screen. " />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Ads show on page</label>
                          <select onChange={(e) => setShow(e.target.value)}>
                            <option value={ads_show} disabled selected>
                              {ads_show}
                            </option>
                            <option value="top">top</option>
                            <option value="right">right</option>
                            <option value="left">left</option>
                            <option value="store">store</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Title</label>
                          <input
                            type="text"
                            placeholder="banner image link"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Ads Image</label>
                          <input
                            type="text"
                            placeholder="banner image link"
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <label>Ads Link</label>
                          <input
                            type="text"
                            placeholder="adds link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-12"></div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="input-fields">
                          <button
                            type="button"
                            className="btn btn-primary all_btn"
                            onClick={UpdatedAdsData}
                          >
                            Update Ads
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditAds;

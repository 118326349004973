import React from "react";
import { useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { URL_LINK, getToken } from "../../Secure/Helper";
import { useState } from "react";

function Blog_Comment() {
  const [data, setData] = useState([]);

  const getComment = async () => {
    const res = await fetch(`${URL_LINK}/user-comment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const response = await res.json();
    const result = response.blogComment;
    setData(result);
  };

  useEffect(() => {
    getComment();
  }, []);

  const deleteHandler = async (id) => {
    alert("Delete Comments");
    const fetc = await fetch(`${URL_LINK}/user-comment/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    await fetc.json();
    getComment();
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Comment</h1>
        <p className="mb-4">
          DataTables is a third party plugin that is used to generate the demo
          table below. For more information about DataTables, please visit the
          <a target="_blank" href="https://cashdost.com">
            official DataTables documentation
          </a>
        </p>
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 font-weight-bold text-primary">Comment Data</h6>
            <form className="navbar-search search-bar">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
              </div>
            </form>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Message</th>
                  <th>Other</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((val, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{ind}</td>
                      <td>
                        <Link to="">{val.name}</Link>
                      </td>
                      <td>{val.email}</td>
                      <td>{val.message}</td>
                      <td>
                        {" "}
                        <button
                          className="funButton"
                          type="button"
                          onClick={() => deleteHandler(val._id)}
                        >
                          <FiTrash2 />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog_Comment;

import React, { useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent";
import { Link } from "react-router-dom";
import {
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiSmile,
  FiTrash2,
} from "react-icons/fi";
import { URL_LINK, getToken } from "../../Secure/Helper";
import Loader from "../../Secure/Loader";
import { FcLink } from "react-icons/fc";

function AllClient() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // console.log(data);
  const [clientData, setClientData] = useState("");
  const { logo, title, link, offer, banner } = clientData;

  // console.log(data);
  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.client);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);



  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure delete for this ${id} `);
    fetch(`${URL_LINK}/store/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  const ViewHandler = (id) => {
    setLoading(true);
    fetch(`${URL_LINK}/store/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setClientData(result.client);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    All Client Menu
                  </h6>
                  <form className="navbar-search search-bar">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control bg-light border-0 small"
                        placeholder="Search for..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </form>
                  <ButtonComponent
                    title="Add Store"
                    links="/add-new-client-menu"
                  />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="product-section">
                      <div className="row">
                        {data?.map((val, ind) => {
                          const {
                            _id,
                            title,
                            offer,
                            logo,
                            client_show,
                          } = val;
                          return (
                            <div
                              key={ind}
                              className="col-lg-3 col-md-3 col-12 mb-4"
                            >
                              <div className="cards">
                                <div className="card-img client-im">
                                  <img src={logo} alt="" />
                                  <span
                                    type="button"
                                    className="button-arrow"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FiMoreVertical />
                                  </span>
                                  <div className="other-fex d-flex align-items-center justify-content-between">
                                    {client_show === "true" ? (
                                      <span className="status-datas client-show">
                                        <FcLink />
                                      </span>
                                    ) : (
                                      " "
                                    )}
                                    {val.post_data === "success" ? (
                                      <span
                                        className="status-datas"
                                        style={{ backgroundColor: "green" }}
                                      >
                                        {val.post_data}
                                      </span>
                                    ) : (
                                      <span
                                        className="status-datas"
                                        style={{ backgroundColor: "red" }}
                                      >
                                        {val.post_data}
                                      </span>
                                    )}
                                  </div>

                                  <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => DeleteHandler(_id)}
                                    >
                                      <FiTrash2 />
                                    </button>
                                    <Link
                                      to={`/edit-signle-client/${_id}`}
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      <FiEdit />
                                    </Link>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                      onClick={() => ViewHandler(_id)}
                                    >
                                      <FiEye />
                                    </button>
                                  </div>
                                </div>
                                <div className="card-content d-flex justify-content-between align-items-center">
                                  <div className="linker">
                                    <h4>{offer}</h4>
                                    <h5 className="d-flex justify-content-start">
                                      <span className="right-icon col-right">
                                        <FiSmile />
                                      </span>
                                      {title}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="client-img">
                <img src={logo} alt="" />
              </div>
              <div className="client-img">
                <img src={banner} alt="" />
              </div>
              <h4>{title}</h4>
              <h5 className="mb-2">{offer}</h5>
              <div className="add-button">
                <Link to={link} type="button" className="btn btn-primary">
                  Campaign Links
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllClient;

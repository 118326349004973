export const URL_LINK = "https://api.cashdost.com/api";
export const FILE_UPLOAD = "https://api.cashdost.com/public/uploads";

// export const URL_LINK = "http://localhost:8000/api";
// export const FILE_UPLOAD = "http://localhost:8000/public/uploads";

export function getToken() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  if (user) {
    return user.token;
  } else {
    return "73j93js857fh589djsjaksj384940DJ34849Djjd";
  }
}
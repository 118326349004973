import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar";
import Navbar from "../../Components/Navbar";
import {
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiSmile,
  FiTrash2,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ButtonComponent from "../../Components/ButtonComponent";
import { URL_LINK, getToken } from "../../Secure/Helper";
import Loader from "../../Secure/Loader";

function AllAds() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = () => {
    setLoading(true);
    fetch(`${URL_LINK}/campaign-banner`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let da = result.ads_data;
        setData(da);
        setLoading(false);
      });
  };

  const DeleteHandler = (id) => {
    setLoading(true);
    alert(`are you sure delete for this ${id} `);
    fetch(`${URL_LINK}/campaign-banner/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        getData();
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="container-fuild">
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 p-0">
            <SideBar />
          </div>
          <div className="col-lg-10 col-md-10 col-12 p-0">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">All Ads</h6>
                  <form className="navbar-search search-bar">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control bg-light border-0 small"
                        placeholder="search only title..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </form>
                  <ButtonComponent title="Add Ads" links="/add-new-Ads" />
                </div>
                <div className="card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="product-section">
                      <div className="row">
                        {data?.reverse()?.map((val, ind) => {
                          return (
                            <div
                              key={ind}
                              className="col-lg-3 col-md-4 col-12 mb-4"
                            >
                              <div className="cards">
                                <div className="card-img">
                                  <img src={val.image} alt="" />
                                  <span
                                    type="button"
                                    className="button-arrow"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <FiMoreVertical />
                                  </span>
                                  {val.post_data === "success" ? (
                                    <span
                                      className="status-datas top-datas"
                                      style={{ backgroundColor: "green" }}
                                    >
                                      {val.post_data}
                                    </span>
                                  ) : (
                                    <span
                                      className="status-datas top-datas"
                                      style={{ backgroundColor: "red" }}
                                    >
                                      {val.post_data}
                                    </span>
                                  )}
                                  <div className="dropdown-menu dropdown-curd dropdown-menu-right">
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => DeleteHandler(val._id)}
                                    >
                                      <FiTrash2 />
                                    </button>
                                    <Link
                                      to={`/edit-new-Ads/${val._id}`}
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      <FiEdit />
                                    </Link>
                                    <Link
                                      // to={`/view-single-product/${_id}`}
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      <FiEye />
                                    </Link>
                                  </div>
                                </div>
                                <div className="card-content">
                                  <h5 className="d-flex justify-content-start">
                                    {" "}
                                    <span className="right-icon col-right">
                                      <FiSmile />
                                    </span>{" "}
                                    {val.title}
                                  </h5>
                                  <p>{val.ads_show} side</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllAds;
